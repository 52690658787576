import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    footerClass: "footer",
    isChatActivated: "",
    isPosterEndImage: false,
    isIframe: false,
    isActivePanicPoster: false,
    playerUrl: "",
    playerUrlEs: "",
    playerUrlCat: "",
    posterStartEn: "",
    posterEndEn: "",
    posterStartEs: "",
    posterEndEs: "",
    posterStartCat: "",
    posterEndCat: "",
    panicPoster: "",
    role: "",
    isLoading: false,
    navBarClass: "",
    showEventComponent: false,
    agendaSrc:
      "https://firebasestorage.googleapis.com/v0/b/la-llucana.appspot.com/o/PaymentHubAgenda.png?alt=media&token=1d50f13d-8e3a-44bd-a7fc-2e2248ae32ec",
    participantsSrc:
      "https://firebasestorage.googleapis.com/v0/b/la-llucana.appspot.com/o/PaymentHubParticipantes.png?alt=media&token=0c8e283b-24fe-4c39-b9a7-aa9d7d752567",
    loadPlayer: false,
  },
  mutations: {
    updateLoadPlayer(state, value) {
      state.loadPlayer = value;
    },
    updateIsActivePanicPoster(state, value) {
      state.isActivePanicPoster = value;
    },
    updateIsPosterEndImage(state, value) {
      state.isPosterEndImage = value;
    },
    updateIsIframe(state, value) {
      state.isIframe = value;
    },
    updateFooterClass(state, value) {
      state.footerClass = value;
    },
    updateIsLoading(state, value) {
      state.isLoading = value;
    },
    updatePlayerUrl(state, value) {
      state.playerUrl = value;
    },
    updatePlayerUrlEs(state, value) {
      state.playerUrlEs = value;
    },
    updatePlayerUrlCat(state, value) {
      state.playerUrlCat = value;
    },
    updatePosterStartEn(state, value) {
      state.posterStartEn = value;
    },
    updatePosterEndEn(state, value) {
      state.posterEndEn = value;
    },
    updatePosterStartEs(state, value) {
      state.posterStartEs = value;
    },
    updatePosterEndEs(state, value) {
      state.posterEndEs = value;
    },
    updatePosterStartCat(state, value) {
      state.posterStartCat = value;
    },
    updatePosterEndCat(state, value) {
      state.posterEndCat = value;
    },
    updateRole(state, value) {
      state.role = value;
    },
    updateNavBarClass(state, value) {
      state.navBarClass = value;
    },
    updateIsChatActivated(state, value) {
      state.isChatActivated = value;
    },
    updateShowEventComponent(state, value) {
      state.showEventComponent = value;
    },
    updatePanicPoster(state, value) {
      state.panicPoster = value;
    },
  },
  actions: {},
  modules: {},
});
