<template>
  <div id="app">
    <NavBar></NavBar>
    <b-container fluid="">
      <router-view />
      <b-overlay :show="isLoading" no-wrap></b-overlay>
    </b-container>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { NavBar },
  computed: {
    ...mapState(["isLoading"]),
  },
};
</script>
<style>
@font-face {
  font-family: "Open Sans Bold";
  src: url("OpenSans-Bold.ttf") format("truetype");
}
* {
  font-family: "Open Sans Bold", serif;
}
</style>
