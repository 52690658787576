import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

const messages = {
  en: {
    EnLanguage: "English",
    EsLanguage: "Spanish",
    CatLanguage: "Catalan",
    inputCode: "Input Access code:",
    btnVerify: "Verify",
    btnSubmit: "Submit",
    labelName: "Name and Surname",
    labelEmail: "Email address",
    placeHolderName: "Enter your name",
    placeHolderEmail: "example@lallucana.com",
    links: "Essential Links",
    ecosystem: "Ecosystem",
    language: "Language",
    playerMessageSendQuestion: "Send questions to the speakers",
    playerBtnSendMessage: "send",
    playerBtnDownloadCsv: "Download chat in CSV file",
    playerLinkAdminPage: "Admin Page",
    playerMessageName: "You",
    playerSpanTotalUsers: "Total users:",
    adminBtnShowUsers: "Show Users",
    adminBtnShowCodes: "Show Codes",
    adminModalTitle: "Create Access Code",
    adminModalWebinarTitle: "Webinar Title English:",
    adminModalWebinarTitleEs: "Webinar Title Spanish:",
    adminModalWebinarTitleCat: "Webinar Title Catalan",
    adminModalWebinarSubTitle: "Webinar Sub Title English:",
    adminModalWebinarSubTitleEs: "Webinar Sub Title Spanish:",
    adminModalWebinarSubTitleCat: "Webinar Sub Title Catalan",
    adminModalDivPromotionalCode: "Access Code:",
    adminModalPlaceholderInputTitle: "Enter title for this Webinar",
    adminModalPlaceholderInputSubTitle: "Enter subtitle for this Webinar",
    adminModalWebinarUrl: "English player url",
    adminModalWebinarUrlEs: "Spanish player url",
    adminModalWebinarUrlCat: "Catalan player url",
    adminModalPlaceholderInputCode: "Enter code",
    adminModalDivChooseDate: "Choose Date:",
    adminModalDivChooseHour: "Choose Hour:",
    adminModalDivChooseDateExpire: "Choose Date to Expire :",
    adminModalDivChooseHourExpire: "Choose Hour to Expire:",
    adminModalWebinarAddQuestion: "Add new Question in English",
    adminModalWebinarAddQuestionEs: "Add new Question in Spanish",
    adminModalWebinarAddQuestionCat: "Add new Question in Catalan",
    adminModalWebinarShowQuestions: "Show English Questions",
    adminModalWebinarShowQuestionsEs: "Show Spanish Questions",
    adminModalWebinarShowQuestionsCat: "Show Catalan Questions",
    adminModalBtnAddCode: "Add",
    adminModalBtnClose: "Close",
    adminModalBtnSaveChanges: "Save Changes",
    adminModalBtnSave: "Save",
    adminModalBtnNewCode: "Create",
    adminThName: "Name",
    adminThSurname: "Surname",
    adminThEmail: "Email",
    adminThCompany: "Company",
    adminThAcceptInfo: "Accept recive information",
    adminThDate: "Date",
    adminThPromotionalCode: "Access Code",
    adminThTitle: "Title",
    adminThSubTitle: "Sub Title",
    adminThBeginningDate: "Beginning Date",
    adminThExpirationDate: "Expiration Date",
    adminThDelete: "Delete",
    adminThPlayerUrl: "Player Url",
    adminPaginatorPrevious: "Previous",
    adminPaginatorNext: "Next",
    adminBtnDownloadCSV: "Download users in CSV file",
    adminBtnDeleteCode: "Delete Event",
    adminBtnDeleteUser: "Delete User",
    adminBtnMoreInfo: "Show More information",
    adminTitleCSVFileName: "Name and Surname",
    adminTitleCSVFileEmail: "Email",
    adminTitleCSVFileDate: "Date",
    adminModalCreateEvent: "Create New Event",
    adminModalEditEvent: "Edit Event",
    adminModalEditEventSuccess: "Event saved successfully",
    adminModalEditAnswer: "Edit Answers",
    adminModalDeleteQuestion: "Delete Question",
    adminModalShowAnswers: "Show Ansers",
    loginCheckBoxOptionA: "Go to player",
    loginCheckBoxOptionB: "Go to admin page",
    loginLabelPassword: "Enter a password",
    loginLabelEmail: "Email",
    loginLabelCompany: "Company Name",
    loginPlaceHolderEmail: "input email",
    loginPlaceHolderPassword: "input password",
    loginPlaceHolderCompany: "input your company name",
    loginBtnSubmit: "Log In",
    loginLabelRegister: "You don't have an account, sign up",
    loginModalTitleResetPassword: "Restore the Password",
    loginLabelResetPassword: "Forgot your password?",
    loginModalButtonSend: "Send",
    loginModalInfo:
      "An email will be sent to you with a link to restore your password, at the address you enter below",
    loginModalSendEmailSuccess: "The email has been sent correctly",
    loginCheckboxLabelSelectOption: "Select an option: ",
    registerBtnSubmit: "Sign Up",
    registerLabelPassword: "Create a password",
    registerLabelRepeatedPassword: "Repeat the password",
    errorMessageNoPassword: "Password is required",
    errorMessageLengthPassword: "Length must be greater than 6",
    errorMessageSamePassword: "Passwords must be the same",
    errorMessageInvalidMailFormat: "Please enter a valid email",
    toastedMessageVerifyCredentials: "Verifying credentials",
    toastedMessageVerifyCode: "Verifying code",
    errorMessageCudNotConnectServer: "Could not connect to the server",
    errorMessageUsernamePassword: "Username or password incorrect",
    errorMessageInvalidPasswordOrUser: "Invalid email or password",
    errorMessageInvalidPromotionalCode: "Invalid code",
    timeoutErrorMessageEmailAdrressFormat:
      "The email address is badly formatted",
    errorMessageNameAndSurname: "Enter a name and surname",
    errorMessageSomethingWrong: "Error, something you entered is wrong",
    errorMessageRequiredSurname: "Require surname",
    errorMessageInvalidDate: "Date is not valid",
    errorMessageUserNotExist: "This user does not exist",
    errorMessageToken: "Invalid token",
    errorMessageAlreadyRegistered: "This user is already registered",
    errorMessageInvalidUser:
      "You do not have permissions to perform this action",
    errorMessageDateExpires: "Date expires",
    successMessageUserDeletedCode: "Deleted successfully",
    successMessageUserUpdateCode: "Access code updated successfully",
    successMessageUserRegister: "Created successfully",
    successMessageVerifiedCode: "Correct Code",
    successMessageResetPassword: "Password updated successfully",
    registerTitle: "Sign Up",
    registerLabelLogin: "You already have an account, log in",
    labelNoTimeSelected: "No time selected",
    secretaryOptionsChatModalTitle: "Choose one of the options",
    secretaryBtnSendModerator: "Send to Moderator",
    secretaryBtnSendResponseUser: "Response User",
    secretaryOptionsChatModalAlertTitle: "Are you sure you want to send it",
    secretaryBtnSendOk: "Send",
    secretaryBtnSendCancel: "Cancel",
    secretaryTabQuestions: "Questions",
    eventInformationTitle: "Event Information",
    eventInformationTitleEn: "English Title",
    eventInformationTitleEs: "Spanish Title",
    eventInformationTitleCat: "Catalan Title",
    eventInformationSubTitleEn: "English Sub Title",
    eventInformationSubTitleEs: "Spanish Sub Title",
    eventInformationSubTitleCatalan: "Catalan Sub Title",
    eventInformationPlayerUrlEn: "English Player Url",
    eventInformationPlayerUrlEs: "Spanish Player Url",
    eventInformationPlayerUrlCat: "Catalan Player Url",
    eventInformationQuestionsEn: "English Questions",
    eventInformationQuestionsEs: "Spanish Questions",
    eventInformationQuestionsCat: "Catalan Questions",
    eventInformationGoBackBtn: "Go Back",
    eventInformationActiveChat: "Chat activated",
    eventInformationTotalUsers: "Total users",
    eventSuccessModified: "Saved Changes",
    userPlayerMessageArrived: "You have a new message",
    secretaryShowGraphs: "Show Graphs",
    secretaryQuestionSended: "Question Sended",
    secretarySendQuestion: "Send Question",
    secretaryShowAnswers: "Show Answers",
    secretaryInputSeconds: "Enter the number of seconds",
    secretaryTitleResponseTime: "Response Time",
    dashboardTitleGraph: "Question",
    dashboardTitleCharts: "Charts",
    adminModalWebinarStartImageCat: "Select an initial cover for Catalan",
    adminModalWebinarEndImageCat: "Select an ending cover for Catalan",
    adminModalWebinarStartImageEs: "Select an initial cover for Spanish",
    adminModalWebinarEndImageEs: "Select an ending cover for Spanish",
    adminModalWebinarStartImageEn: "Select an initial cover for English",
    adminModalWebinarEndImageEn: "Select an ending cover for English",
    adminModalWebinarBrowseBtnLabel: "Browse",
    adminModalWebinarPlaceholderUploadImage: "Choose an image...",
    adminModalWebinarUploadBtnLabel: "Upload image",
    secretaryWebinarTabChatLabel: "Chat with the secretary",
    moderatorWebinarTabGeneralLabel: "General",
    moderatorWebinarTabChatLabel: "Chat with the moderator",
    adminBtnManageEvent: "Manage event",
    eventInformationActiveQuestions: "Questions activated",
    eventInformationActiveChroma: "Chroma activated",
    adminPanicPosterLabel: "Panic poster",
    adminManageUsersModalDeleteTitle:
      "Are you sure that you want to delete this user?",
    adminManageUsersModalDeleteInfo:
      "If you proceed, delete the user permanently",
    adminManageUsersTitle: "Users",
    adminManageUsersPlaceHolderFindEmail: "find user by email",
    adminManageUsersLabelNoUsers: "There are no users\n ",
    adminNavBarManageUsers: "Manage Users",
    adminNavBarManageCompanies: "Manage Companies",
    adminNavBarLogOut: "Log out",
    componentCreateUserTitle: "Create a User",
    componentCreateUserLabelName: "User name",
    componentCreateUserPlaceholderName: "insert user name",
    componentCreateUserLabelSurname: "User Surname",
    componentCreateUserPlaceholderSurname: "insert user surname",
    componentCreateUserLabelWebinarCompanies:
      "Select Webinar company where user is going to join",
    componentCreateUserLabelUserCompany: "Company which the user belongs to",
    componentCreateUserLabelUserRole: "Select user role",
    componentCreateUserLabelWithPassword: "With password",
    componentCreateUserBtnCreate: "Create User",
    adminManageUsersModalImportCSVTile: "Import new users from CSV file",
    adminManageUsersModalImportCSVBtnImport: "Import",
    adminManageUsersModalImportCSVBtnDownload: "Download Example",
    adminManageUsersBtnShow: "Show Users",
    adminManageUsersBtnCreate: "Create Users",
    adminManageUsersBtnImportUsers: "Import Users from CSV",
    componentShowUsersBtnFind: "Search",
    adminManageUsersModalImportCSVPText:
      "You can download and example to know the structure of your CSV file",
    resetPasswordBtn: "Reset your password",
    userPlayerModalRegisterTitle:
      "Enter your name and surname to be able to send questions",
    userPlayerModalRegisterStart: "Start",
    acceptTerms: "",
  },
  es: {
    EnLanguage: "Inglés",
    EsLanguage: "Castellano",
    CatLanguage: "Catalán",
    inputCode: "Ingrese el código promocional: ",
    btnVerify: "Verificar",
    btnSubmit: "Enviar",
    labelName: "Nombre y Apellido",
    labelEmail: "Correo electrónico",
    placeHolderName: "Ingrese su nombre y su apellido",
    placeHolderEmail: "example@lallucana.com",
    plugins: "Plugins de CLI instalados",
    links: "Enlaces esenciales",
    ecosystem: "Ecosistema",
    language: "Idioma",
    playerMessageSendQuestion: "Escriba aquí sus preguntas para el Q&A final",
    playerBtnSendMessage: "Enviar",
    playerBtnDownloadCsv: "Descarga el chat en un fichero CSV",
    playerLinkAdminPage: "Página de Administrador",
    playerMessageName: "Tú",
    playerSpanTotalUsers: "Usuarios totales: ",
    adminBtnShowUsers: "Mostrar Usuarios",
    adminBtnShowCodes: "Mostrar códigos",
    adminModalTitle: "Crear código promocional",
    adminModalWebinarTitle: "Título del seminario web en Inglés:",
    adminModalWebinarTitleEs: "Título del seminario web en Castellano:",
    adminModalWebinarTitleCat: "Título del seminario web en Catalán",
    adminModalWebinarSubTitle: "Subtítulo del seminario web en Inglés: ",
    adminModalWebinarSubTitleEs: "Subtítulo del seminario web en Castellano:",
    adminModalWebinarSubTitleCat: "Subtítulo del seminario web en Catalán",
    adminModalWebinarUrl: "Url del player en Inglés",
    adminModalWebinarUrlEs: "Url del player en Castellano",
    adminModalWebinarUrlCat: "Url del player en Catalán",
    adminModalDivPromotionalCode: "Código de Acceso:",
    adminModalPlaceholderInputTitle:
      "Ingrese el título para este seminario web",
    adminModalPlaceholderInputSubTitle:
      "Ingrese el subtítulo para este seminario web",
    adminModalPlaceholderInputCode: "Ingrese el código",
    adminModalDivChooseDate: "Elegir fecha:",
    adminModalDivChooseHour: "Elegir hora:",
    adminModalDivChooseDateExpire: "Elija la fecha de caducidad:",
    adminModalDivChooseHourExpire: "Elija la hora de caducidad:",
    adminModalBtnAddCode: "Añadir",
    adminModalBtnClose: "Cerrar",
    adminModalBtnSaveChanges: "Guardar Cambios",
    adminModalBtnSave: "Guardar",
    adminModalWebinarAddQuestion: "Añade una nueva Pregunta en Inglés",
    adminModalWebinarAddQuestionEs: "Añade una nueva Pregunta en Castellano",
    adminModalWebinarAddQuestionCat: "Añade una nueva Pregunta en Catalán",
    adminModalWebinarShowQuestions: "Mostrar las preguntas en Inglés",
    adminModalWebinarShowQuestionsEs: "Mostrar las preguntas en Castellano",
    adminModalWebinarShowQuestionsCat: "Mostrar las preguntas en Catalan",
    adminModalBtnNewCode: "Crear",
    adminThName: "Nombre",
    adminThSurname: "Apellido",
    adminThEmail: "Correo Electrónico",
    adminThCompany: "Empresa",
    adminThAcceptInfo: "Acepta recibir información",
    adminThDate: "Fecha",
    adminThPlayerUrl: "Player Url",
    adminThPromotionalCode: "Código de Acceso",
    adminThTitle: "Título",
    adminThSubTitle: "Subtítulo",
    adminThBeginningDate: "Fecha de Inicio",
    adminThExpirationDate: "Fecha de Caducidad",
    adminThDelete: "Eliminar",
    adminPaginatorPrevious: "Anterior",
    adminPaginatorNext: "Siguiente",
    adminBtnDownloadCSV: "Descargar usuarios en un fichero CSV",
    adminBtnDeleteCode: "Eliminar Evento",
    adminBtnDeleteUser: "Eliminar Usuario",
    adminBtnMoreInfo: "Mostrar más información",
    adminTitleCSVFileName: "Nombre y Apellido",
    adminTitleCSVFileEmail: "Correo electrónico",
    adminTitleCSVFileDate: "Fecha",
    adminModalCreateEvent: "Crear un Evento",
    adminModalEditEvent: "Editar Evento",
    adminModalEditEventSuccess: "Evento guardado con exito",
    adminModalEditAnswer: "Editar Respuestas",
    adminModalDeleteQuestion: "Eliminar Pregunta",
    adminModalShowAnswers: "Mostrar Respuestas",
    loginCheckBoxOptionA: "Ir al player",
    loginCheckBoxOptionB: "Ir a la página del administrador",
    loginLabelEmail: "Introduce tu correo electrónico",
    loginLabelCompany: "Nombre de la Empresa:",
    loginLabelPassword: "Ingrese la Contraseña",
    loginPlaceHolderEmail: "Ingrese el correo electrónico",
    loginPlaceHolderPassword: "Ingrese la contraseña",
    loginPlaceHolderCompany: "Ingrese el nombre de su empresa",
    loginBtnSubmit: "Iniciar sesión",
    loginLabelTitle: "Inicia sesión para ver el evento en directo",
    loginLabelRegister: "Regístrate para crear tu cuenta de acceso",
    loginLabelResetPassword: "Has olvidado tu contraseña?",
    loginModalTitleResetPassword: "Restaurar la Contraseña",
    loginModalButtonSend: "Enivar",
    loginModalInfo:
      "Se le enviará un correo con un link, para restaurar su contraseña, en la dirección que introduzca a continuación",
    loginModalSendEmailSuccess: "Se ha enviado el email correctamente",
    loginCheckboxLabelSelectOption: "Selecciona una opción: ",
    registerBtnSubmit: "Regístrate",
    registerLabelPassword: "Crea una Contraseña",
    registerLabelRepeatedPassword: "Escribe la Contraseña de Nuevo",
    errorMessageNoPassword: "Se requiere de una contraseña",
    errorMessageLengthPassword: "La longitud debe ser mayor que 6",
    errorMessageSamePassword: "Las contraseñas deben coicidir",
    errorMessageInvalidMailFormat:
      "Por favor introduzca una dirección de correo electrónico válida",
    toastedMessageVerifyCredentials: "Verificación de credenciales",
    toastedMessageVerifyCode: "Verificando el código",
    errorMessageCudNotConnectServer: "No se pudo conectar con el servidor",
    errorMessageUsernamePassword: "Usuario o contraseña incorrectos",
    errorMessageInvalidPasswordOrUser:
      "Correo electrónico o contraseña no válidos",
    errorMessageInvalidPromotionalCode: "Codigo incorrecto",
    timeoutErrorMessageEmailAdrressFormat:
      "La dirección de correo electrónico está mal formateada",
    errorMessageNameAndSurname: "Ingrese un nombre y un apellido",
    successMessageUserDeletedCode: "Eliminado correctamente",
    successMessageUserUpdateCode: "Código promocional actualizado con éxito",
    successMessageUserRegister: "Creado con éxito",
    successMessageVerifiedCode: "Código correcto",
    successMessageResetPassword: "Contraseña actualizada correctamente",
    registerTitle: "Registrate",
    registerLabelLogin: "Accede al evento con tu email",
    errorMessageRequiredSurname: "Se necesita un apellido",
    errorMessageInvalidDate: "La fecha no es válida",
    errorMessageUserNotExist: "Este usuario no existe",
    errorMessageToken: "El token no es valido",
    errorMessageAlreadyRegistered: "Este usuario ya está registrado",
    errorMessageInvalidUser: "No tienes permisos para realizar esta acción",
    errorMessageDateExpires: "La fecha ha expirado",
    errorMessageSomethingWrong: "Error, algo que ingresaste está mal",
    labelNoTimeSelected: "Sin tiempo seleccionado",
    secretaryOptionsChatModalTitle: "Escoge una de las opciones",
    secretaryBtnSendModerator: "Enviar al Moderador",
    secretaryBtnSendResponseUser: "Responder al Usuario",
    secretaryOptionsChatModalAlertTitle: "Seguro que lo quieres enviar",
    secretaryBtnSendOk: "Enviar",
    secretaryBtnSendCancel: "Cancelar",
    secretaryTabQuestions: "Preguntas",
    eventInformationTitle: "Información del Evento",
    eventInformationTitleEn: "Título en Inglés",
    eventInformationTitleEs: "Título en Castellano",
    eventInformationTitleCat: "Titulo en Catalan",
    eventInformationSubTitleEn: "Subtítulo en Inglés",
    eventInformationSubTitleEs: "Subtítulo en Castellano",
    eventInformationSubTitleCatalan: "Subtítulo en Catalán",
    eventInformationPlayerUrlEn: "Url del Player en Inglés",
    eventInformationPlayerUrlEs: "Url del Player en Castellano",
    eventInformationPlayerUrlCat: "Url del Player en Catalán",
    eventInformationQuestionsEn: "Preguntas en Inglés",
    eventInformationQuestionsEs: "Preguntas en Castellano",
    eventInformationQuestionsCat: "Preguntas en Catalán",
    eventInformationGoBackBtn: "Regresar",
    userPlayerMessageArrived: "Tienes un nuevo mensaje",
    eventInformationTotalUsers: "Total de usuarios",
    eventInformationActiveChat: "Chat activado",
    eventSuccessModified: "Cambios Guardados",
    secretaryShowGraphs: "Mostrar Gráficos",
    secretaryQuestionSended: "Pregunta  Enviada",
    secretarySendQuestion: "Envia la Pregunta",
    secretaryShowAnswers: "Mostrar Respuestas",
    secretaryInputSeconds: "Introduzca el número de segundos",
    secretaryTitleResponseTime: "Tiempo de Respuesta",
    dashboardTitleGraph: "Pregunta",
    dashboardTitleCharts: "Gráficos",
    adminModalWebinarStartImageCat:
      "Añade una imagen de portada inicial en Catalán",
    adminModalWebinarEndImageCat:
      "Añade una imagen de portada final en Catalán",
    adminModalWebinarStartImageEs:
      "Añade una imagen de portada inicial en Castellano",
    adminModalWebinarEndImageEs:
      "Añade una imagen de portada final en Castellano",
    adminModalWebinarStartImageEn:
      "Añade una imagen de portada inicial en Inglés",
    adminModalWebinarEndImageEn: "Añade una imagen de portada final en Inglés",
    adminModalWebinarBrowseBtnLabel: "Buscar",
    adminModalWebinarPlaceholderUploadImage: "Selecciona una imagen...",
    adminModalWebinarUploadBtnLabel: "Subir imagen",
    secretaryWebinarTabChatLabel: "Chat con el secretario",
    moderatorWebinarTabGeneralLabel: "General",
    moderatorWebinarTabChatLabel: "Chat con el moderador",
    adminBtnManageEvent: "Gestionar evento",
    eventInformationActiveQuestions: "Preguntas activadas",
    eventInformationActiveChroma: "Chroma activado",
    adminPanicPosterLabel: "Póster del pánico",
    adminManageUsersModalDeleteTitle:
      "¿Estás seguro que quieres eliminar a este usuario?",
    adminManageUsersModalDeleteInfo:
      "Si continúa, elimina al usuario de forma permanente",
    adminManageUsersTitle: "Usuarios",
    adminManageUsersPlaceHolderFindEmail: "buscar usuario por email",
    adminManageUsersLabelNoUsers: "No hay usuarios",
    adminNavBarManageUsers: "Gestiona los Usuarios",
    adminNavBarManageCompanies: "Gestiona las Empresas",
    adminNavBarLogOut: "Cerrar sesión",
    componentCreateUserTitle: "Crear un Usuario",
    componentCreateUserLabelName: "Nombre del Usuario",
    componentCreateUserPlaceholderName: "ingrese el nombre del usuario",
    componentCreateUserLabelSurname: "Apellido del Usuario",
    componentCreateUserPlaceholderSurname: "ingrese el apellido del usuario",
    componentCreateUserLabelWebinarCompanies:
      "Seleccione la empresa del seminario web a la que se unirá el usuario",
    componentCreateUserLabelUserCompany: "Empresa la cual pertenece el usuario",
    componentCreateUserLabelUserRole: "Selecciona el rol del usuario",
    componentCreateUserLabelWithPassword: "Con contraseña",
    componentCreateUserBtnCreate: "Crea el Usuario",
    adminManageUsersModalImportCSVTile:
      "Importa nuevos usuarios mediante un fichero CSV",
    adminManageUsersModalImportCSVBtnImport: "Importar",
    adminManageUsersModalImportCSVBtnDownload: "Descarga un ejemplo",
    adminManageUsersBtnShow: "Mostar Usuarios",
    adminManageUsersBtnCreate: "Crear Usuarios",
    adminManageUsersBtnImportUsers: "Importar Usuarios des de un fichero CSV",
    componentShowUsersBtnFind: "Buscar",
    adminManageUsersModalImportCSVPText:
      "Puedes descargar un ejemplo para conocer la estructura de tu archivo CSV",
    resetPasswordBtn: "Restablecer su contraseña",
    userPlayerModalRegisterTitle:
      "Introduzca su nombre y apellido para poder enviar preguntas",
    userPlayerModalRegisterStart: "Empezar",
    acceptTerms: "Al enviar esta solicitud, estás aceptando nuestros ",
    errorMessageNoAcceptTerms:
      "Has de aceptar los términos y condiciones y la política de privacidad",
  },
  cat: {
    EnLanguage: "Anglès",
    EsLanguage: "Castellà",
    CatLanguage: "Català",
    inputCode: "Introduïu el codi promocional: ",
    btnVerify: "Comprovar",
    btnSubmit: "Enviar",
    labelName: "Nom i Cognom",
    labelEmail: "Correu electrònic",
    placeHolderName: "Introdueix el teu nom",
    placeHolderEmail: "example@lallucana.com",
    plugins: "Plugins de CLI instal·lats",
    links: "Enllaços essencials",
    ecosystem: "Ecosistema",
    language: "Idioma",
    playerMessageSendQuestion: "Envia preguntes als ponents",
    playerBtnSendMessage: "Enviar",
    playerBtnDownloadCsv: "Descarrega el xat en un fitxer CSV",
    playerLinkAdminPage: "Pàgina d'Administrador",
    playerMessageName: "Tu",
    playerSpanTotalUsers: "Nombre de membres: ",
    adminBtnShowUsers: "Mostra Usuaris",
    adminBtnShowCodes: "Mostra els codis",
    adminModalTitle: "Crear codi promocional",
    adminModalWebinarTitle: "Títol del seminari web en Anglès: ",
    adminModalWebinarTitleEs: "Títol del seminari web en Castellà: ",
    adminModalWebinarTitleCat: "Títol del seminari web en Català: ",
    adminModalWebinarSubTitle: "Subtítol del seminari web en Anglès: ",
    adminModalWebinarSubTitleEs: "Subtítol del seminari web en Castellà: ",
    adminModalWebinarSubTitleCat: "Subtítol del seminari web en Català: ",
    adminModalDivPromotionalCode: "Codi d'Accés: ",
    adminModalPlaceholderInputTitle:
      "Introduïu el títol per a aquest seminari web",
    adminModalPlaceholderInputSubTitle:
      "Introduïu el subtítol per a aquest seminari web",
    adminModalPlaceholderInputCode: "Introduïu el codi",
    adminModalDivChooseDate: "Triar data:",
    adminModalDivChooseHour: "Triar hora: ",
    adminModalDivChooseDateExpire: "Trieu la data de caducitat:",
    adminModalDivChooseHourExpire: "Trieu la data de caducitat",
    adminModalBtnAddCode: "Afegir",
    adminModalBtnClose: "Tancar",
    adminModalBtnSaveChanges: "Desa els Canvis",
    adminModalBtnSave: "Desar",
    adminModalWebinarAddQuestion: "Afegeix una nova Pregunta en Anglès",
    adminModalWebinarAddQuestionEs: "Afegeix una nova Pregunta en Castellà",
    adminModalWebinarAddQuestionCat: "Afegeix una nova Pregunta en Català",
    adminModalWebinarShowQuestions: "Mostrar les preguntes en Anglès",
    adminModalWebinarShowQuestionsEs: "Mostrar les preguntes en Castellà",
    adminModalWebinarShowQuestionsCat: "Mostrar les preguntas en Català",
    adminModalBtnNewCode: "Crear",
    adminModalWebinarUrl: "Url del player en Anglès",
    adminModalWebinarUrlEs: "Url del player en Castellà",
    adminModalWebinarUrlCat: "Url del player en Català",
    adminThName: "Nom",
    adminThSurname: "Cognom",
    adminThEmail: "Correu Electrònic",
    adminThCompany: "Empresa",
    adminThAcceptInfo: "Accepta rebre informació",
    adminThDate: "Data",
    adminThPlayerUrl: "Player Url",
    adminThPromotionalCode: "Codi d'Accés",
    adminThTitle: "Títol",
    adminThSubTitle: "Subtítol",
    adminThBeginningDate: "Data d'inici",
    adminThExpirationDate: "Data de caducitat",
    adminThDelete: "Eliminar",
    adminPaginatorPrevious: "Anterior",
    adminPaginatorNext: "Següent",
    adminBtnDownloadCSV: "Descarregar usuaris en un fitxer CSV",
    adminBtnDeleteCode: "Eliminar Esdeveniment",
    adminBtnDeleteUser: "Eliminar Usuari",
    adminBtnMoreInfo: "Mostrar més informació",
    adminTitleCSVFileName: "Nom i Cognoms",
    adminTitleCSVFileEmail: "Correu electrònic",
    adminTitleCSVFileDate: "Data",
    adminModalCreateEvent: "Crear un Esdeveniment",
    adminModalEditEvent: "Editar l'Esdeveniment",
    adminModalEditEventSuccess: "Esdeveniment guardat amb èxit",
    adminModalEditAnswer: "Editar les Respostes",
    adminModalDeleteQuestion: "Eliminar la Pregunta",
    adminModalShowAnswers: "Mostrar les Respostes",
    loginCheckboxLabelSelectOption: "Selecciona una de les opcions",
    loginCheckBoxOptionA: "Anar a la pàgina d'administrador",
    loginCheckBoxOptionB: "Anar al player d'administrador",
    loginLabelEmail: "Correu Electrònic",
    loginLabelCompany: "Nom de l'Empresa",
    loginLabelPassword: "Escriu la contrasenya",
    loginPlaceHolderEmail: "Introduïu el correu electrònic",
    loginPlaceHolderPassword: "Introduïu la contrasenya",
    loginPlaceHolderCompany: "Introduïu el nom de la vostra empresa",
    loginBtnSubmit: "Iniciar Sessió",
    loginLabelRegister: "No tens compte, registra't",
    loginLabelResetPassword: "No recordes la contrasenya?",
    loginModalTitleResetPassword: "Restaura la Contrasenya",
    loginModalButtonSend: "Enviar",
    loginModalSendEmailSuccess: "S'ha enviat l'email correctament",
    loginModalInfo:
      "Se li enviarà un correu amb un link, per restaurar la seva contrasenya, a l'adreça que introdueixi a continuació",
    registerLabelPassword: "Crea un Contrasenya",
    registerLabelRepeatedPassword: "Escriu la Contrasenya de Nou",
    registerBtnSubmit: "Registra't",
    errorMessageNoPassword: "Es requereix d'una contrasenya",
    errorMessageLengthPassword: "La longitud ha de ser més gran que 6",
    errorMessageSamePassword: "Les contrasenyes han de ser iguals",
    errorMessageInvalidMailFormat:
      "Si us plau introdueixi una adreça de correu electrònic vàlida",
    toastedMessageVerifyCredentials: "Verificació de credencials",
    toastedMessageVerifyCode: "Verificant el codi",
    errorMessageCudNotConnectServer: "No s'ha pogut connectar amb el servidor",
    errorMessageUsernamePassword: "Usuari o contrasenya incorrectes",
    errorMessageInvalidPasswordOrUser:
      "Correu electrònic o contrasenya no vàlids",
    errorMessageInvalidPromotionalCode: "Codi incorrecte",
    timeoutErrorMessageEmailAdrressFormat:
      "La direcció de correu electrònica està malament",
    errorMessageNameAndSurname: "Introduïu un nom i un cognom",
    successMessageUserDeletedCode: "Eliminat correctament",
    successMessageUserUpdateCode: "Codi d'accés actualitzat amb èxit",
    successMessageUserRegister: "Creat amb èxit",
    successMessageVerifiedCode: "Codi correcte",
    successMessageResetPassword: "Contrasenya actualitzada correctament",
    registerTitle: "Registra't",
    registerLabelLogin: "Ja tens compte, inicia sessió",
    errorMessageRequiredSurname: "Es necessita un cognom",
    errorMessageInvalidDate: "La data no és vàlida",
    errorMessageUserNotExist: "Aquest usuari no existeix",
    errorMessageToken: "El token no és vàlid",
    errorMessageAlreadyRegistered: "Aquest usuari ja està registrat",
    errorMessageInvalidUser: "No tens permisos per a realitzar aquesta acció",
    errorMessageDateExpires: "La data ha expirat",
    errorMessageSomethingWrong:
      "Error, alguna cosa que heu introduït està malament",
    labelNoTimeSelected: "Sense temps seleccionat",
    secretaryOptionsChatModalTitle: "Escull una de les opcions",
    secretaryBtnSendModerator: "Enviar al Moderador",
    secretaryBtnSendResponseUser: "Respondre  al Usuari",
    secretaryOptionsChatModalAlertTitle: "Estas segur que vols enviar-ho",
    secretaryBtnSendOk: "Enviar",
    secretaryBtnSendCancel: "Cancel·lar",
    secretaryTabQuestions: "Preguntes",
    eventInformationTitle: "Informació de l'Esdeveniment",
    eventInformationTitleEn: "Títol en Anglès",
    eventInformationTitleEs: "Títol en Castellà",
    eventInformationTitleCat: "Titol en Català",
    eventInformationSubTitleEn: "Subtítol en Anglès",
    eventInformationSubTitleEs: "Subtítol en Castellà",
    eventInformationSubTitleCatalan: "Subtítol en Catalan",
    eventInformationPlayerUrlEn: "Url del Player en Anglès",
    eventInformationPlayerUrlEs: "Url del Player en Castellà",
    eventInformationPlayerUrlCat: "Url del Player en Català",
    eventInformationQuestionsEn: "Preguntes en Anglès",
    eventInformationQuestionsEs: "Preguntes en Castellà",
    eventInformationQuestionsCat: "Preguntes en Català",
    eventInformationGoBackBtn: "Enrrera",
    eventInformationActiveChat: "Xat activat",
    eventInformationTotalUsers: "Usuaris totals",
    eventSuccessModified: "Canvis Guardats",
    userPlayerMessageArrived: "Tens un nou missatge",
    secretaryShowGraphs: "Mostrar Gràfics",
    secretaryQuestionSended: "Pregunta  Enviada",
    secretarySendQuestion: "Envia la Pregunta",
    secretaryShowAnswers: "Mostra les Respostes",
    secretaryInputSeconds: "Introdueixi el número de segons",
    secretaryTitleResponseTime: "Temps de Resposta",
    dashboardTitleGraph: "Pregunta",
    dashboardTitleCharts: "Gràfics",
    adminModalWebinarStartImageCat:
      "Afegeix una imatge de portada inicial en Català",
    adminModalWebinarEndImageCat:
      "Afegeix una imatge de portada final en Català",
    adminModalWebinarStartImageEs:
      "Afegeix una imatge de portada inicial en Castellà",
    adminModalWebinarEndImageEs:
      "Afegeix una imatge de portada final en Castellà",
    adminModalWebinarStartImageEn:
      "Afegeix una imatge de portada inicial en Anglès",
    adminModalWebinarEndImageEn:
      "Afegeix una imatge de portada final en Anglès",
    adminModalWebinarBrowseBtnLabel: "Buscar",
    adminModalWebinarPlaceholderUploadImage: "Selecciona una imatge...",
    adminModalWebinarUploadBtnLabel: "Carregar imatge",
    secretaryWebinarTabChatLabel: "Xat amb el secretari",
    moderatorWebinarTabGeneralLabel: "General",
    moderatorWebinarTabChatLabel: "Xat amb el moderador",
    adminBtnManageEvent: "Gestionar esdeveniment",
    eventInformationActiveQuestions: "Preguntes activades",
    eventInformationActiveChroma: "Chroma activat",
    adminPanicPosterLabel: "Pòster del pànic",
    adminManageUsersModalDeleteTitle:
      "Estàs segur que vols eliminar aquest usuari?",
    adminManageUsersModalDeleteInfo:
      "Si continueu, eliminareu l'usuari permanentment",
    adminManageUsersTitle: "Usuaris",
    adminManageUsersPlaceHolderFindEmail: "buscar usuari per email",
    adminManageUsersLabelNoUsers: "No hi han usuaris",
    adminNavBarManageUsers: "Gestiona als Usuaris",
    adminNavBarManageCompanies: "Gestiona les Empreses",
    adminNavBarLogOut: "Tancar sessió",
    componentCreateUserTitle: "Crear un Usuari",
    componentCreateUserLabelName: "Nom de l'Usuari",
    componentCreateUserPlaceholderName: "introdueix el nom de l'usuari",
    componentCreateUserLabelSurname: "Cognom de l'usuari",
    componentCreateUserPlaceholderSurname: "Introdueix el cognom de l'usuari",
    componentCreateUserLabelWebinarCompanies:
      "Seleccioneu l'empresa de seminari web on s'unirà l'usuari",
    componentCreateUserLabelUserCompany: "Empresa la qual pertany l'usuari",
    componentCreateUserLabelUserRole: "Selecciona el rol que tindrà l'usuari",
    componentCreateUserLabelWithPassword: "Amb contrasenya",
    componentCreateUserBtnCreate: "Crea l'usuari",
    adminManageUsersModalImportCSVTile:
      "Importa nous usuarios mitjançant un fitxer CSV",
    adminManageUsersModalImportCSVBtnImport: "Importar",
    adminManageUsersModalImportCSVBtnDownload: "Descarrega un exemple",
    adminManageUsersBtnShow: "Veure els Usuaris",
    adminManageUsersBtnCreate: "Crear Usuaris",
    adminManageUsersBtnImportUsers: "Importar Usuaris des d'un fitxer CSV",
    componentShowUsersBtnFind: "Buscar",
    adminManageUsersModalImportCSVPText:
      "Podeu descarregar-ne un exemple per conèixer l'estructura del fitxer CSV",
    resetPasswordBtn: "Restablir la contrasenya",
    userPlayerModalRegisterTitle:
      "Introduïu el vostre nom i cognoms per poder enviar preguntes",
    userPlayerModalRegisterStart: "Començar",
    acceptTerms: "",
  },
};

const i18n = new VueI18n({
  locale: "es", // set locale
  fallbackLocale: "en", // set fallback locale
  messages, // set locale messages
});

export default i18n;
