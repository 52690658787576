<template>
  <b-navbar
    :class="navBarClass"
    id="navBar"
    type="light"
    toggleable="sm"
    variant="light"
  >
    <b-navbar-brand :disabled="true">
      <b-img
        src="https://firebasestorage.googleapis.com/v0/b/paymenthub-6f141.appspot.com/o/unnamed%20v2.png?alt=media&token=e4c3cd9d-fc37-4f09-bb0b-92910571010d"
        class="img"
        alt="Logo Payment Innovation Hub"
      >
      </b-img>
    </b-navbar-brand>
    <b-container
      v-if="
        $route.path !== '/event/prensa' &&
        $route.path !== '/register' &&
        $route.path !== '/'
      "
    >
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    </b-container>
  </b-navbar>
</template>
<script>
import { auth } from "@/firebaseConfig/config";
import Cryptr from "crypto-js";
import { mapState } from "vuex";
//import apiService from "@/service/service";

export default {
  name: "NavBar",
  data() {
    return {
      languages: [
        { flag: "us", language: "en" },
        { flag: "es", language: "es" },
        { flag: "cat", language: "cat" },
      ],
      usersRef: "",
      isUpdated: false,
    };
  },
  computed: {
    ...mapState(["navBarClass", "role"]),
  },
  methods: {
    /**
     * Function that log out user from the page
     */
    logOut() {
      this.$store.commit("updateIsLoading", true);
      localStorage.clear();
      sessionStorage.clear();
      this.onUserDisconnect();
      this.deleteAllCookies();
      this.deleteCookie("UserLogged", "/player");
      this.deleteCookie("code", "/");
      this.deleteCookie("code", "/");
      this.deleteCookie("isChat", "/");
      this.deleteCookie("email", "/");
      this.deleteCookie("userRole", "/");
      this.deleteCookie("areQuestions", "/player");
      this.deleteCookie("name", "/");
      this.$store.commit("updateRole", "");
    },
    /**
     * Change language of the page
     * @param locale
     */
    changeLocale(locale) {
      this.$i18n.locale = locale;
      for (const language of this.languages) {
        locale === language.language
          ? document.getElementById(language.language).classList.add("active")
          : document
              .getElementById(language.language)
              .classList.remove("active");
      }
    },
    /**
     * Function for set cookie passed by params
     * @param cookieName
     * @param cookieValue
     * @param encrypted
     */
    setCookie(cookieName, cookieValue, encrypted) {
      const now = new Date();
      const time = now.getTime();
      const expireTime = time + 1000 * 36000;
      now.setTime(expireTime);
      encrypted
        ? (document.cookie =
            cookieName +
            "=" +
            Cryptr.AES.encrypt(cookieValue, "secret key 1234").toString() +
            "; expires=" +
            now.toUTCString())
        : (document.cookie =
            cookieName + "=" + cookieValue + "; expires=" + now.toUTCString());
    },
    /**
     * Get value of cookie passed by param
     * @param cname
     * @returns {string}
     */
    getCookie(cname) {
      //function that passes the key of the cookie to obtain the value
      const name = cname + "=";
      const ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    /**
     * Delete cookie passed by param
     * @param name
     */
    deleteCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    },
    /**
     * Disconnects user from real time database and sign out user from firebase auth
     */
    async onUserDisconnect() {
      if (!this.isUpdated) {
        this.isUpdated = true;
        //const cookie = this.getCookie('email')
        //const code = this.getCookie('code').toLowerCase().replace(/\s/g, "")
        // const data = {email: cookie, code: code}
        try {
          //const accessToken = await app.auth().currentUser.getIdToken()
          //await apiService.logOutUser(accessToken, data)
          await auth.signOut();
          localStorage.clear();
          this.isUpdated = false;
          await this.$router.push({ path: `/event/prensa` });
          this.$store.commit("updateIsLoading", false);
        } catch (e) {
          console.log(e.message);
        }
      }
    },
    deleteAllCookies() {
      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++)
        this.deleteCookie(cookies[i].split("=")[0]);
      cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    createCookie(name, value, days) {
      let expires;
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }
      document.cookie = name + "=" + value + expires + "; path=/endirecto";
      document.cookie = name + "=" + value + expires + "; path=/";
    },
  },
  created() {
    //const code = this.getCookie("code").toLowerCase().replace(/\s/g, "");
    //this.usersRef = app.database().ref(`lallucana/${code}/users`);
  },
  mounted() {
    //Change default  language to catalan
    const lang = window.navigator.language;
    if (lang.includes("es")) {
      this.$i18n.locale = "es";
    }
    // else if (lang.includes('cat')) {
    //   this.$i18n.locale = 'cat'
    // } else {
    //   this.$i18n.locale = 'en'
    // }
    // for (const language of this.languages) {
    //   this.$i18n.locale === language.language ? document.getElementById(language.language).classList.add('active') : document.getElementById(language.language).classList.remove('active')
    // }
  },
};
</script>

<style scoped>
.navDisplayNone {
  display: none;
}
@media (max-width: 1810px) {
  img {
    width: 77em;
  }
}
@media (max-width: 1518px) {
  img {
    width: 71em;
  }
}
@media (max-width: 1518px) {
  img {
    width: 65em;
  }
}
@media (max-width: 1258px) {
  img {
    width: 60em;
  }
}
@media (max-width: 1158px) {
  img {
    width: 55em;
  }
}
@media (max-width: 1058px) {
  img {
    width: 49em;
  }
}
@media (max-width: 975px) {
  img {
    width: 45em;
  }
}
@media (max-width: 875px) {
  img {
    width: 40em;
  }
}
@media (max-width: 785px) {
  img {
    width: 35em;
  }
}
@media (max-width: 690px) {
  img {
    width: 30em;
  }
}
@media (max-width: 590px) {
  img {
    width: 25em;
  }
}
@media (max-width: 490px) {
  img {
    width: 20em;
  }
}
nav {
  background-color: white !important;
}
</style>
